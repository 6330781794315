import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/images/logo-stattus4.webp";
import "../../assets/scss/global.css";

export class Header extends Component {
  closeMenu(e) {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
    }
  }

  render() {
    return (
      <div>
        <div className="az-header" style={{position: "fixed", top: 0, width: "100%", backgroundColor: '#8086c0'}}>
          <div className="container" style={{maxWidth: "calc(100vw - 300px)", left: 115, position: "relative"}}>
            <div className="az-header-left">
              <a href="#/" className="az-logo">
                <img src={logo} style={{width: 115}}/>
              </a>
              <a
                id="azMenuShow"
                onClick={event => this.toggleHeaderMenu(event)}
                className="az-header-menu-icon d-lg-none"
                href="#/"
              >
                <img src={logo} style={{width: 115}}/>
              </a>
            </div>
            <div className="az-header-menu">
              <div className="az-header-menu-header">
                <Link to="/" className="az-logo">
                  <img src={logo} style={{width: 115}}/>
                </Link>
                <a
                  href="#/"
                  onClick={event => this.toggleHeaderMenu(event)}
                  className="close"
                >
                  &times;
                </a>
              </div>
              <ul className="nav">
                <li
                  className={
                    this.isPathActive("/dashboard")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/dashboard" className="nav-link">
                    <i className="typcn typcn-home-outline"></i> Home
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/installPoints")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/installPoints" className="nav-link">
                    <i className="typcn typcn-location-outline"></i> Pontos de instalação
                  </Link>
                </li>

                {/* <li
                  className={
                    this.isPathActive("/connectivity")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/connectivity" className="nav-link">
                    <i className="typcn typcn-wi-fi"></i> Conectividade
                  </Link>
                </li> */}

              </ul>
            </div>

            <div className="az-header-right">
              {/* <i className="fas fa-bell"></i>            */}
              <Dropdown className="az-profile-menu">
                <Dropdown.Toggle as={"a"} className="az-img-user">
                  <img
                    src={require("../../assets/images/stt4.png")}
                    alt=""
                  ></img>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="az-dropdown-header d-sm-none">
                    <a
                      href="#/"
                      onClick={event => this.closeMenu(event)}
                      className="az-header-arrow"
                    >
                      <i className="icon ion-md-arrow-back"></i>
                    </a>
                  </div>
                  <div className="az-header-profile">
                    <div className="az-img-user">
                      <img
                        src={require("../../assets/images/stt4.png")}
                        alt=""
                      ></img>
                    </div>
                    <h6>Stattus4</h6>
                    <span>Premium Member</span>
                  </div>

                  <a href="#/" className="dropdown-item">
                    <i className="typcn typcn-user-outline"></i> Meus dados
                  </a>
                  <a href="#/" className="dropdown-item">
                    <i className="typcn typcn-edit"></i> Editar dados
                  </a>
                  <a href="#/" className="dropdown-item">
                    <i className="typcn typcn-time"></i> Logs
                  </a>
                  <a href="#/" className="dropdown-item">
                    <i className="typcn typcn-cog-outline"></i> Configurações
                  </a>
                  <Link to="/general-pages/signin" className="dropdown-item">
                    <i className="typcn typcn-power-outline"></i> Sair
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Header);
